#crossHairContainer{
    position:absolute;
    width:100vw;
    height:calc(100vh - 144px);
    z-index:20;
    pointer-events:none;
    top:70px;
    left:0;
    display:flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
}
.show{
    opacity:1
}
.hide{opacity:0}