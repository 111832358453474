#northArrow{
    position:fixed;
    right:0;
    bottom:74px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: start;
    width:80px;
    height:95px;
    padding-top:10px;
    background-color:white;
    color:rgb(0, 0, 0);
    font-size: 12px;
    font-family: 'Courier New', Courier, monospace;
    z-index:40;
}
.northArrowSVG{
    width:50px;
    height:50px;
    margin:5px;
}