
.cw{
background-image: url("rotateCW.svg");
}
.ccw{
    background-image: url("rotateCCW.svg");
}

button.fixNorth>img{
   object-fit: contain;
   width:100%;
   height:100%;
}
