#dropdownMenu{
    
    background-color:black;
    color:rgb(182,182,182);
    width:80%;
    height:28px;
    text-align:center;
    margin-top:10px;
 
}
.dropdownClosed{
    overflow:hidden;
    border:1px solid gray;
    z-index:1;

}
.dropdownOpen{
    border:1px solid white;
    border-bottom:1px solid rgba(0,0,0,0);
    overflow:unset;
    z-index:300;
}
.dropdownSelected{
    height:28px;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}
.dropDownItem{
    width:100%;
    background-color:rgb(31, 31, 31);
    color:white;
    border-top:1px solid gray;
    padding-top:5px;
    padding-bottom:5px;
}