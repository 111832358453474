div.myReposContainer{
    height: calc(100vh - 120px);
    width:100vw;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y:scroll;
}
div.reposList{
    height: auto;
    width:100vw;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
div.repo{
    width:100%;
    height:60px;
    border-bottom:1px solid gray;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content:flex-start;
    color:rgb(201, 201, 201);
}
button.icon{
    margin:10px;
    width:35px;
    height:35px;
    border:none;
    background-color: transparent;
    background-size:80%;
    background-position: center;
    background-repeat: no-repeat;
}


button.refresh{
    background-image:url('../../assets/icons/refresh.svg')
}
button.trash{
    background-image:url('../../assets/icons/trash.svg')
}
div.repoName{
    font-weight:bold;
    color:white;
    margin-left:10px;
}
div.repoNotes{
    font-style:italic;
}
div.repoDetails{
    width:100%;
}