#toastContainer{
    position:absolute;
    width:100vw;
    height:auto;
    z-index:20;
    pointer-events:none;
    bottom:70px;
    left:0;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
}
div.toast{
    background-color:white;
    border:2px solid gray;
    border-radius:5px;
    filter:drop-shadow(1px 1px 2px rgba(0,0,0,0.5));
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    width:250px;
    height:50px;
    font-size:14px;
    pointer-events:all;
}
div.toast>div:first-child{
    width:50px;
    height:50px;
    background-position:center;
    background-repeat:no-repeat;
    border-radius: 5px 0px 0px 5px;
}
div.toast.warning>div:first-child{
background-color: rgb(219, 219, 40);
background-image:url('../../assets/icons/warn.svg')
}
div.toast>div:last-child{
    margin:10px;
    font-family: Arial, Helvetica, sans-serif;
    font-stretch:ultra-expanded;
    font-weight: bold;
    color:gray;
    cursor:pointer;
}
div.toast.success{
    color:black;
}
div.toast.success>div:first-child{
    background-color: rgb(40, 219, 58);
    background-size: 50%;;
    background-image:url('../../assets/icons/success.svg')
    }