
#splashScreen{
    position:absolute;
    top:0;
    right:0;
    width:100vw;
    height:100vh;
    background-color: black;
    transition: opacity 1s;
    z-index:300;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:white;
    text-align: center;
    pointer-events:none;
}
img{
    width:200px;
}
#title{
    opacity:0;
    transition: opacity 1s;
}
.title{
  
 font-family: Georgia, 'Times New Roman', Times, serif;
 font-size:50px;

}
.version{
    margin-top:20px;
    margin-bottom:20px;
    font-size: small;
    font-family: 'Courier New', Courier, monospace;
}
.copy{
    font-size: x-small;
}
div.installButtons{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top:20px;
    pointer-events: all;
    height:150px;
}
button.installButton{
    margin-top:10px;
    height:50px;
    color:white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: rgb(49, 49, 49);
    border-radius:10px;
    cursor:pointer;
    width:100%;
}
div.buttons{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height:150px;
    display:none;
    visibility:hidden;
    opacity:0;
    transition:opacity 1s;
}
img#statusImage{
    width:0px;
    height:0px;
    margin:10px;
    transition: width 0.5s, height 0.5s;
}
.loading {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }
@keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .hide{
    display:none;
    visibility:hidden;
  }
  .show{
    display:unset;
    visibility:unset;
  }